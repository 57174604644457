<template>
  <div>
    <!--begin::User-->
    <!--    <div class="card card-custom">-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>

      <!--      <div class="card-header flex-wrap border-0 pt-6 pb-0">-->
      <!--        <div class="card-title m-0">-->
      <h3 class="card-label">
        {{ $t('items.collected_item') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <!--        </div>-->
      <!--      </div>-->


      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('items.basic_information')" active @click="toggleTab('basic_information')">
          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 pt-5">
                    <div class="form-group">
                      <custom-upload-image-2 :upload="dir_upload" :start-link="'base'" :image-url="image_url"
                                             @file="listenerAttachment"></custom-upload-image-2>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('items.name') }}<span class="text-danger">*</span></label>
                    <input type="text" @input="repeatingData" v-model="data.name" class="form-control"
                           :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('items.name')"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
                  </div>

                </div>
              </div>
              <div class="card card-custom mt-5">
                <div class="card-body row">
<!--                  <div class="col-lg-6 mb-5">-->
<!--                    <label>{{ $t('items.initial_stock_level') }}</label>-->
<!--                    <input type="text" v-model="data.initial_stock_level" class="form-control"-->
<!--                           :class="validation && validation.initial_stock_level ? 'is-invalid' : ''"-->
<!--                           :placeholder="$t('items.initial_stock_level')"/>-->
<!--                    <span v-if="validation && validation.initial_stock_level"-->
<!--                          class="fv-plugins-message-container invalid-feedback">-->
<!--                                {{ validation.initial_stock_level[0] }}-->
<!--                            </span>-->
<!--                  </div>-->
<!--                  <div class="col-lg-6 mb-5">-->
<!--                    <label>{{ $t('items.low_stock_threshold') }}</label>-->
<!--                    <input type="text" v-model="data.low_stock_threshold" class="form-control"-->
<!--                           :class="validation && validation.low_stock_threshold ? 'is-invalid' : ''"-->
<!--                           :placeholder="$t('items.low_stock_threshold')"/>-->
<!--                    <span v-if="validation && validation.low_stock_threshold"-->
<!--                          class="fv-plugins-message-container invalid-feedback">-->
<!--                                {{ validation.low_stock_threshold[0] }}-->
<!--                            </span>-->
<!--                  </div>-->

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.re_order') }}</label>
                    <input type="number" v-model="data.re_order" class="form-control"
                           :class="validation && validation.re_order ? 'is-invalid' : ''"
                           :placeholder="$t('items.re_order')"/>
                    <span v-if="validation && validation.re_order" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.re_order[0] }}
                                </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sum_available_qty') }}</label>
                    <input type="text" v-model="data.sum_available_qty" disabled class="form-control"
                           :placeholder="$t('items.sum_available_qty')"/>
                  </div>

                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sum_stock_on_hand_qty') }}</label>
                    <input type="number" disabled v-model="data.sum_stock_on_hand_qty" class="form-control"
                           :placeholder="$t('items.sum_stock_on_hand_qty')"/>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.minimum_qty') }}</label>
                    <input type="number" v-model="data.minimum_qty" min="0" class="form-control"
                           :class="validation && validation.minimum_qty ? 'is-invalid' : ''"
                           :placeholder="$t('items.minimum_qty')"/>
                    <span v-if="validation && validation.minimum_qty"
                          class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.minimum_qty[0] }}
                                </span>
                  </div>

<!--                  <div class="col-lg-6 mb-5">-->
<!--                    <label>&ensp;</label>-->
<!--                    <b-form-checkbox size="lg" v-model="data.is_tracking" name="check-button" switch>-->
<!--                      {{ $t('items.is_tracking') }}-->
<!--                    </b-form-checkbox>-->
<!--                  </div>-->
                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_manufacture" name="check-button" switch>
                      {{ $t('items.is_manufacturing_item') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sku_code') }}</label>
                    <input type="text" v-model="data.sku_code" class="form-control"
                           :class="validation && validation.sku_code ? 'is-invalid' : ''"
                           :placeholder="$t('items.sku_code')"/>
                    <span v-if="validation && validation.sku_code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.sku_code[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.barcode') }}</label>
                    <input type="text" v-model="data.barcode" class="form-control"
                           :class="validation && validation.barcode ? 'is-invalid' : ''"
                           :placeholder="$t('items.barcode')"/>
                    <span v-if="validation && validation.barcode" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.barcode[0] }}
                                </span>
                  </div>

                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.category') }}<span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                      <treeselect
                          :options="categories"
                          :load-options="loadOptions"
                          :multiple="false"
                          :value="data.category_id"
                          @select="updateValue"
                          :searchable="true"
                          :class="validation && validation.category_id ? 'is-invalid' : ''"
                          :show-count="true"
                          :no-children-text="$t('No_sub_options')"
                          :no-options-text="$t('No_options_available')"
                          :no-results-text="$t('No_results_found')"
                          :placeholder="$t('Select')">
                      </treeselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/categories" target="_blank"><i class="fa fa-plus"
                                                                                                  style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.category_id"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.category_id[0] }}
                        </span>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.brand') }}</label>
                    <div class="input-group mb-3">
                      <multiselect v-model="brand" :placeholder="$t('items.brand')" label="name" track-by="id"
                                   :options="brands" :multiple="false" :taggable="true"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/brands" target="_blank"><i class="fa fa-plus"
                                                                                              style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span v-if="validation && validation.brand_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.brand_id[0] }}
                        </span>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.unit') }}<span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                      <multiselect v-model="unit"
                                   :placeholder="$t('items.unit')"
                                   label="name"
                                   track-by="id"
                                   :options="units"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/settings/units" target="_blank"><i class="fa fa-plus"
                                                                                             style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">
                                                    {{ $t('type_at_least_three_letters') + ' ' + $t('items.unit') }}.
                                                </span>

                    <span v-if="validation && validation.unit_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.unit_id[0] }}
                        </span>
                  </div>
<!--                  <div class="col-lg-6 mb-5">-->
<!--                    <label>&ensp;</label>-->
<!--                    <b-form-checkbox v-model="data.is_min" name="check-button" size="lg" switch>{{ $t('minimum_unit') }}-->
<!--                    </b-form-checkbox>-->
<!--                  </div>-->
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.return_days') }}</label>
                    <input type="number" v-model="data.allowable_return_days" class="form-control" @keydown="positiveNumber"
                           :class="validation && validation.allowable_return_days ? 'is-invalid' : ''"
                           :placeholder="$t('items.return_days')"/>
                    <span v-if="validation && validation.allowable_return_days" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.allowable_return_days[0] }}
                            </span>
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>{{ $t('items.tags') }}</label>
                    <multiselect v-model="data.tags"
                                 :placeholder="$t('items.tags')"
                                 label="name" track-by="id"
                                 :options="tags_list"
                                 :multiple="true"
                                 :taggable="true"
                                 @tag="addTag"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation.tags" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.tags[0] }}
                        </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>&ensp;</label>
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}
                    </b-form-checkbox>
                  </div>

                </div>
              </div>

              <div class="card card-custom mt-5">
                <div class="card-body row">
                  <div class="col-lg-6">
                    <label>{{ $t('items.special_description') }}</label>
                    <textarea type="text" v-model="data.special_description" class="form-control" style="height: 86px;"
                              :class="validation && validation.special_description ? 'is-invalid' : ''"
                              :placeholder="$t('items.special_description')"></textarea>
                    <span v-if="validation && validation.special_description"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.special_description[0] }}
                        </span>
                  </div>
                  <div class="col-lg-6">
                    <label>{{ $t('items.description') }}</label>
                    <textarea type="text" v-model="data.description" class="form-control" style="height: 86px;"
                              :class="validation && validation.description ? 'is-invalid' : ''"
                              :placeholder="$t('items.description')"></textarea>
                    <span v-if="validation && validation.description"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                        </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
<!--          <div class="row mt-5">-->
<!--            <div class="col-md-12">-->

<!--              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">-->
<!--                <h6 class="mt-2">{{ $t('items.units') }}</h6>-->
<!--                <button class="btn btn-primary mr-2" type="button" @click="addUnitToRepeater">{{$t('add_more') }}</button>-->
<!--              </div>-->
<!--              <div class="bg-white">-->
<!--                <table class="table" @keyup.alt.enter="addUnitToRepeater" @keyup.alt.46="deleteUnitFromRepeater(0)">-->
<!--                  <thead>-->
<!--                  <tr class="text-center">-->
<!--                    <th width="40%">{{ $t('items.unit') }}</th>-->
<!--                    <th width="40%">{{ $t('items.number') }}</th>-->
<!--                    <th width="40%">{{ $t('status') }}</th>-->
<!--                    <th class="text-center" width="20%"></th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                  <tr v-for="(row, index) in data_units" :key="index"  :class="getClassValidationRepeaterByIndex(index)">-->
<!--                    <td>-->
<!--                      <multiselect v-model="row.unit"-->
<!--                                   :multiple="false"-->
<!--                                   :options="units"-->
<!--                                   :placeholder="$t('items.unit')"-->
<!--                                   :show-labels="false"-->
<!--                                   :show-no-options="false"-->
<!--                                   :show-no-results="false"-->
<!--                                   :taggable="false"-->
<!--                                   @input="onChangeUnitsRepeater(index)"-->
<!--                                   label="name"-->
<!--                                   track-by="id">-->
<!--                      </multiselect>-->
<!--                    <span v-if="validation && validation[`units_list.${index}.item`]" class="fv-plugins-message-container invalid-feedback">-->
<!--                          {{ validation[`units_list.${index}.unit`][0] }}-->
<!--                        </span>-->
<!--                    </td>-->
<!--                    <td>-->
<!--                      <input v-model="row.number" @input="onChangeUnitsRepeater(index)" :placeholder="$t('items.number')" class="form-control" type="text"/>-->
<!--                    <span v-if="validation && validation[`units_list.${index}.number`]" class="fv-plugins-message-container invalid-feedback">-->
<!--                          {{ validation[`units_list.${index}.number`][0] }}-->
<!--                        </span>-->
<!--                    </td>-->
<!--                    <td>-->
<!--                      <b-form-checkbox v-model="row.is_active" name="check-button" size="lg" switch class="text-center"></b-form-checkbox>-->
<!--                    </td>-->
<!--                    <td class="text-center">-->
<!--                      <v-icon class="text-danger" @click="deleteUnitFromRepeater(index)">mdi-delete</v-icon>-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <template v-if="idEdit == null">
            <div class="row">
              <div class="col-12 mt-5">
                <div v-if="!data.category_id">
                  <b-alert show variant="info">
                    <h6>{{ $t('items.you_must_select_category_to_add_group_items') }}</h6>
                  </b-alert>
                </div>
                <div v-if="aspects_list.length <= 0 && data.category_id">
                  <b-alert show variant="warning">
                    <h6>{{ $t('items.add_aspects_for_category') }}</h6>
                    <a href="/items/aspects/create" target="_blank" class="btn btn-outline-white btn-sm mr-2 my-2">{{ $t('items.add_aspects') }}</a>
                  </b-alert>
                </div>
              </div>
            </div>

          </template>

          <div class="row" v-if="idEdit == null">
            <div class="col-12 mt-5" v-if="aspects_list.length > 0">
              <div class="card card-custom">
              <div class="card-body row">
                <div v-for="(aspect, index) in aspects_list" :key="index" class="col-lg-6 mb-5">
                  <label>{{ aspect.name }}</label>
                  <multiselect v-if="aspect.attribute_style == 2"
                               v-model="aspect.values"
                               :placeholder="$t('items.values')"
                               label="name"
                               track-by="id"
                               :options="aspect.aspect_values"
                               :multiple="true"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <input v-else :type="aspect.value_type == 1 ? 'number': 'text'" v-model="aspect.values"
                         class="form-control"/>


                </div>
                <div class="col-lg-6 mb-5 mt-5">
                  <button @click="repeatingData" type="button" class="btn btn-primary mr-2">{{
                      $t('generate_items')
                    }}
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-5">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('items.sub_items') }}</h6>
                <!--                    <button type="button" class="btn btn-primary" @click="addItemRowToList">{{ $t('add_more') }}</button>-->
              </div>
              <div class="table-responsive bg-white">
                <!--                    <table class="table table-row-bordered w-max-content" @keyup.enter="addItemRowToList" @keyup.46="removeItemRowFromList">-->
                <table class="table table-row-bordered table-custom-padding">
                  <thead>
                  <tr>
                    <th width="300px">{{ $t('items.name') }}</th>
                    <th width="300px">{{ $t('items.sku') }}</th>
                    <th width="200px">{{ $t('items.sell_price') }}</th>
                    <th width="200px">{{ $t('items.purchase_price') }}</th>
                    <th width="200px">{{ $t('items.open_stock_value') }}</th>
                    <th width="200px">{{ $t('items.open_stock_quantity') }}</th>
                    <th>{{ $t('actions') }}</th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in group_items" :key="index">

                    <td><input disabled v-model="row.name" type="text" class="form-control"></td>
                    <td><input v-model="row.sku_code" type="text" class="form-control"></td>
                    <td><input v-model="row.sale_price" min="0" type="number" class="form-control"></td>
                    <td><input v-model="row.purchase_price" min="0" type="number" class="form-control"></td>
                    <td><input v-model="row.open_stock_value" min="0" type="number" class="form-control"></td>
                    <td><input v-model="row.open_stock_quantity" min="0" type="number" class="form-control"></td>

                    <td>
                      <v-icon style="color: #dc3545;" small v-if="group_items.length > 1 && idEdit == null"
                              @click="removeItemRowFromList(index)">mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </b-tab>

        <b-tab :title="$t('items.financial_information')" lazy :disabled="idEdit == null" @click="toggleTab('financial_information')">
          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.purchase_price') }}</label>
                    <input type="number" v-model="data.purchase_price" class="form-control"
                           :class="validation && validation.purchase_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.purchase_price')"/>
                    <span v-if="validation && validation.purchase_price"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.purchase_price[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.sale_price') }}</label>
                    <input type="number" v-model="data.sale_price" class="form-control"
                           :class="validation && validation.sale_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.sale_price')"/>
                    <span v-if="validation && validation.sale_price"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.sale_price[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.mrp') }}</label>
                    <input type="number" v-model="data.mrp" class="form-control"
                           :class="validation && validation.mrp ? 'is-invalid' : ''"
                           :placeholder="$t('items.mrp')"/>
                    <span v-if="validation && validation.mrp"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.mrp[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.min_sale_price') }}</label>
                    <input type="number" v-model="data.min_sale_price" class="form-control"
                           :class="validation && validation.min_sale_price ? 'is-invalid' : ''"
                           :placeholder="$t('items.min_sale_price')"/>
                    <span v-if="validation && validation.min_sale_price"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.min_sale_price[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.profit_margin') }}</label>
                    <input type="number" v-model="data.profit_margin" class="form-control"
                           :class="validation && validation.profit_margin ? 'is-invalid' : ''"
                           :placeholder="$t('items.profit_margin')"/>
                    <span v-if="validation && validation.profit_margin"
                          class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.profit_margin[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.discount_type') }}</label>
                    <div class="input-group">
                      <select name="" id="discount_type" v-model="data.discount_type" class="custom-select">
                        <option v-for="row in discount_type_list" :value="row.id" :key="row.id">
                          {{ row.name }}
                        </option>
                      </select>
                      <span v-if="validation && validation.discount_type"
                            class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.discount_type[0] }}
                                        </span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('items.discount') }}</label>
                    <input type="number" v-model="data.discount" class="form-control"
                           :class="validation && validation.discount ? 'is-invalid' : ''"
                           :placeholder="$t('items.discount')"/>
                    <span v-if="validation && validation.discount" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.discount[0] }}
                                    </span>
                  </div>
<!--                  <div class="col-lg-6 mb-5">-->
<!--                    <label>{{ $t('items.total_taxes') }}</label>-->
<!--                    <input type="number" v-model="data.total_taxes" class="form-control" disabled-->
<!--                           :class="validation && validation.total_taxes ? 'is-invalid' : ''"-->
<!--                           :placeholder="$t('items.total_taxes')"/>-->
<!--                    <span v-if="validation && validation.total_taxes" class="fv-plugins-message-container invalid-feedback">-->
<!--                                            {{ validation.total_taxes[0] }}-->
<!--                                    </span>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
            <div class="col-lg-12 pt-8">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('items.taxes') }}</h6>
                <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
              </div>
              <div class="bg-white">
                <table class="table" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                  <thead>
                  <tr>
                    <th width="50%">{{ $t('items.tax') }}</th>
                    <th width="30%">{{ $t('items.percentage') }}</th>
                    <th width="30%">{{ $t('minimum') }}</th>
                    <th width="30%">{{ $t('items.apply_priority_tax') }}</th>
                    <th width="20%" class="text-center">{{ $t('actions') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(tx, index) in data_taxes" :key="index">
                    <td>
                      <multiselect v-model="tx.tax"
                                   :placeholder="$t('items.tax')"
                                   label="name"
                                   track-by="id"
                                   :options="taxes"
                                   :multiple="false"
                                   @input="handlingPercentageValue(index)"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                    </td>
                    <td>
                      <input type="text" v-model="tx.percentage" class="form-control"
                             :placeholder="$t('items.percentage')"/>

                    </td>
                    <td>
                      <input type="number" v-model="tx.minimum" class="form-control" :placeholder="$t('minimum')"/>

                    </td>
                    <td>
                      <select name="" id="apply_priority_tax" v-model="tx.priority" @input="onPriorityTaxChanged(index)" class="custom-select">
                        <option v-for="row in apply_priority_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                    </td>
                    <td class="text-center">
                      <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>


          <!--                <div class="col-lg-6 mb-5">-->
          <!--                  <label>{{ $t('items.raw_material_price') }}</label>-->
          <!--                  <input type="number" v-model="data.raw_material_price" class="form-control"-->
          <!--                         :class="validation && validation.raw_material_price ? 'is-invalid' : ''"-->
          <!--                         :placeholder="$t('items.purchase_price')"/>-->
          <!--                  <span v-if="validation && validation.raw_material_price"-->
          <!--                        class="fv-plugins-message-container invalid-feedback">-->
          <!--                                            {{ validation.raw_material_price[0] }}-->
          <!--                                    </span>-->
          <!--                </div>-->
          <!--                <div class="col-lg-6 mb-5">-->
          <!--                  <label>{{ $t('items.purchase_price_last_3_invoice') }}</label>-->
          <!--                  <input type="number" v-model="data.purchase_price_last_3_invoice" class="form-control"-->
          <!--                         :class="validation && validation.purchase_price_last_3_invoice ? 'is-invalid' : ''"-->
          <!--                         :placeholder="$t('items.purchase_price_last_3_invoice')"/>-->
          <!--                  <span v-if="validation && validation.purchase_price_last_3_invoice"-->
          <!--                        class="fv-plugins-message-container invalid-feedback">-->
          <!--                                            {{ validation.purchase_price_last_3_invoice[0] }}-->
          <!--                                    </span>-->
          <!--                </div>-->
          <!--                <div class="col-lg-6 mb-5">-->
          <!--                  <label>{{ $t('items.purchase_price_last_6_invoice') }}</label>-->
          <!--                  <input type="number" v-model="data.purchase_price_last_6_invoice" class="form-control"-->
          <!--                         :class="validation && validation.purchase_price_last_6_invoice ? 'is-invalid' : ''"-->
          <!--                         :placeholder="$t('items.purchase_price_last_6_invoice')"/>-->
          <!--                  <span v-if="validation && validation.purchase_price_last_6_invoice"-->
          <!--                        class="fv-plugins-message-container invalid-feedback">-->
          <!--                                            {{ validation.purchase_price_last_6_invoice[0] }}-->
          <!--                                    </span>-->
          <!--                </div>-->
          <!--                <div class="col-lg-6 mb-5">-->
          <!--                  <label>{{ $t('items.purchase_price_last_10_invoice') }}</label>-->
          <!--                  <input type="number" v-model="data.purchase_price_last_10_invoice" class="form-control"-->
          <!--                         :class="validation && validation.purchase_price_last_10_invoice ? 'is-invalid' : ''"-->
          <!--                         :placeholder="$t('items.purchase_price_last_10_invoice')"/>-->
          <!--                  <span v-if="validation && validation.purchase_price_last_10_invoice"-->
          <!--                        class="fv-plugins-message-container invalid-feedback">-->
          <!--                                            {{ validation.purchase_price_last_10_invoice[0] }}-->
          <!--                                    </span>-->
          <!--                </div>-->

        </b-tab>

        <b-tab v-if="$can('items.laundry_services')" lazy :title="$t('items.laundry_services')" @click="toggleTab('laundry_services')">
          <laundry-services :services-list.sync="services" :taxes="taxes" :main-item-id="idEdit"></laundry-services>
        </b-tab>

        <b-tab :title="$t('items.inventory_details')" lazy :disabled="idEdit == null" @click="toggleTab('inventory_details')">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-custom">
                <div class="card-body">
                  <div class="bg-white">
                    <table class="table table-custom-padding">
                      <thead>

                      <tr>
                        <th>{{ $t('inventory_statistics.inventory') }}</th>
                        <th>{{ $t('inventory_statistics.product') }}</th>
                        <th>{{ $t('inventory_statistics.financial_year') }}</th>
                        <th>{{ $t('inventory_statistics.sales_booking') }}</th>
                        <th>{{ $t('inventory_statistics.available_stock_qty') }}</th>
                        <th>{{ $t('inventory_statistics.open_stock_quantity_total') }}</th>
                        <th>{{ $t('inventory_statistics.stock_on_hand_qty') }}</th>
                        <th>{{ $t('inventory_statistics.purchase_on_way') }}</th>
                        <th>{{ $t('inventory_statistics.max_quantity') }}</th>
                        <th>{{ $t('inventory_statistics.min_quantity') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(statistic, index) in statistics_item" :key="'statistic'+index">
                        <td>{{statistic.inventory}}</td>
                        <td>{{statistic.product}}</td>
                        <td>{{statistic.financial_year}}</td>
                        <td>{{statistic.sales_booking}}</td>
                        <td>{{statistic.available_stock_qty}}</td>
                        <td>{{statistic.open_stock_quantity_total}}</td>
                        <td>{{statistic.stock_on_hand_qty}}</td>
                        <td>{{statistic.purchase_on_way}}</td>
                        <td>
                          <input type="text" v-model="statistic.max_quantity" class="form-control"/>
                        </td>
                        <td>
                          <input type="text" v-model="statistic.min_quantity" class="form-control"/>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab v-if="idEdit" :title="$t('activity_log.activity_log')" lazy @click="toggleTab('activity_log')">
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-custom">
                <div class="card-body row">
                  <item-quantity-activity-log :id="idEdit"></item-quantity-activity-log>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <div class="pl-0 pr-0" v-if="tab_name != 'activity_log'">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save(1)">{{ $t('save') }}</button>
            <button v-if="!isEditing" type="button" class="btn btn-primary mr-2" @click="save(2)">{{ $t('save_and_create_new') }}</button>
            <button v-if="!isEditing" type="button" class="btn btn-primary mr-2" @click="save(3)">{{ $t('save_and_redirect_to_main_page') }}</button>
          </div>
        </div>
      </div>
    </div>


    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ItemQuantityActivityLog from "@/view/content/widgets/details/ItemQuantityActivityLog";
import {mapGetters, mapState} from "vuex";
import LaundryServices from "@/view/pages/modules/items/collectedItems/LaundryServices.vue";
import dataTypeNumbers from "@/core/config/mix/dataTypeNumbers";

export default {
  name: "from-collected-items",
  components: {LaundryServices, 'item-quantity-activity-log': ItemQuantityActivityLog},
  data() {
    return {
      mainRoute: 'items/items',
      mainRouteDependency: 'base/dependency',
      key: 'Items',
      dir_upload: 'items',
      categories: [],
      brands: [],
      tags_list: [],
      taxes: [],
      discount_type_list: [

        {id: 1, name: this.$t('discount_type.percentage')},
        {id: 2, name: this.$t('discount_type.value')},
      ],
      apply_priority_list: [
        {id: 1, name: 1},
        {id: 2, name: 2},
        {id: 3, name: 3},
        {id: 4, name: 4},
        {id: 5, name: 5},
      ],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        category_id: null,
        brand_id: null,
        description: null,
        special_description: null,
        sku_code: null,
        barcode: null,
        image: null,
        tags: [],
        is_active: true,
        is_manufacture: true,
        is_tracking: false,
        re_order: null,

        purchase_price: null,
        raw_material_price: null,
        purchase_price_last_3_invoice: null,
        purchase_price_last_6_invoice: null,
        purchase_price_last_10_invoice: null,
        sale_price: null,
        min_sale_price: null,
        profit_margin: null,
        discount: null,
        discount_type: 1,
        initial_stock_level: null,
        low_stock_threshold: null,
        minimum_qty: null,
        item_type: 3,
        total_taxes: 0,
        mrp: null,
        unit_id: null,
        allowable_return_days: null,
        is_min: false,
      },
      isEditing: this.$route.params.id ? true : false,
      validation: null,
      image_url: null,
      reloadUploadAttachment: true,
      repeater_taxes: {id: null, tax: null, percentage: null, priority: null, minimum: 0},
      data_taxes: [],

      items: [],
      group_items: [],
      group_items_repeater: {
        id: null,
        name: null,
        sku_code: null,
        sale_price: null,
        purchase_price: null,
        open_stock_value: null,
        open_stock_quantity: null
      },

      brand: null,
      tab_name: 'basic_information',
      PlanMessage: null,
      aspects_list: [],
      inner_name: null,
      unit: null,
      units: [],
      statistics_item: [],

      /**
       * laundry service data
       */
      services: [],
      repeater_unit: {unit: null, unit_id: null, number: null, is_active: true},
      data_units: [],
      repeater_validation: []
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    brand: function (val) {
      if (val && val.id) {
        this.data.brand_id = val.id;
      } else {
        this.data.brand_id = null;
      }
    },
    unit: function (val) {
      if (val && val.id) {
        this.data.unit_id = val.id;
      } else {
        this.data.unit_id = null;
      }

    },
    data_taxes: {
      handler(val) {
        if (val.length > 0) {
          this.calcPercentageForTotal();
        }
      },
      deep: true
    },
  },
  methods: {
    ...dataTypeNumbers,

    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    checkingUnitsRepeater(_status = true){
      let _main_validation_status = false;
      this.validation = [];
      this.data_units.forEach((row, index) => {
        let _validation_status = false;
        if(!row.unit && _status){
          this.validation[`units_list.${index}.unit`] = [];
          this.validation[`units_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

        if(this.data.is_min){
          if (parseFloat(this.data_units[index].number) > 999999.999){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit_with_is_min');
            _validation_status = true;
          }
          if (parseFloat(this.data_units[index].number) < 1.001){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit_with_is_min');
            _validation_status = true;
          }
          if (parseFloat(this.data_units[index].number)  == 1){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit_should_not_be_1');
            _validation_status = true;
          }
        }else{
          if (parseFloat(this.data_units[index].number) > 999999.999){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit');
            _validation_status = true;

          }
          if (parseFloat(this.data_units[index].number) < 0.001){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit');
            _validation_status = true;
          }
          if (parseFloat(this.data_units[index].number)  == 1){
            this.validation[`units_list.${index}.number`] = [];
            this.validation[`units_list.${index}.number`][0] = this.$t('the_number_field_value_of_unit_should_not_be_1');
            _validation_status = true;
          }
        }

        if (_validation_status && _status){
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        }else{
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0){
        _main_validation_status = true;
      }
      if (_main_validation_status){
        let _units = [...this.data_units];
        this.data_units = [];
        this.data_units = _units;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }

      return _main_validation_status;
    },
    save(_action, event) {
      if(this.checkingUnitsRepeater()){
        event.preventDefault();
        return false;
      }else{
        if (this.isEditing) {
          this.update();
        } else {
          this.create(_action);
        }

      }
    },
    redirectActionAfterSave(_action){
      switch (_action){
        case 1:
          this.$router.push({name: 'collected-items.edit', params: {id: this.idEdit}}, () => {
            window.location.reload();
          });
          break;
        case 2:
          this.$router.push({query: {_pgid: Date.now()}});
          // this.$router.push({name: 'collected-items.create'});
          break;
        case 3:
          this.$router.push({name: 'collected-items.index'});
          break;
        default:
          this.$router.push({name: 'collected-items.edit', params: {id: this.idEdit}}, () => {
            window.location.reload();
          });
          break;
      }
    },
    create(_action) {
      // this.data.is_active = this.data.is_active ? '1' : '0';
      // this.data.is_manufacture = this.data.is_manufacture ? '1' : '0';
      // this.data.is_tracking = this.data.is_tracking ? '1' : '0';
      let _units = [...this.data_units];
      _units = _units.map((row)=>{
        return {unit_id: row.unit_id, number: row.number, is_active: row.is_active}
      });
      _units = _units.filter((row)=> parseFloat(row.number) > 0);

      this.data.aspects = this.aspects_list;

      ApiService.post(this.mainRoute, {
        ...this.data,
        taxes: this.data_taxes,
        group_items: this.group_items,
        statistics_item: this.statistics_item,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.idEdit = response.data.data.id;
        // this.$router.push({name: 'collected-items.edit', params: {id: response.data.data.id}});
        // this.$router.push({name: 'collected-items.edit', params: {id: response.data.data.id}}, () => {
        //   window.location.reload();
        // });
        this.redirectActionAfterSave(_action);
        // if (response.data.data.id){
        //     this.getData();
        // }
        // this.$router.push('/items/collected-items');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      // this.data.is_active = this.data.is_active ? '1' : '0';
      // this.data.is_manufacture = this.data.is_manufacture ? '1' : '0';
      // this.data.is_tracking = this.data.is_tracking ? '1' : '0';

      let _units = [...this.data_units];
      _units = _units.map((row)=>{
        return {unit_id: row.unit_id, number: row.number, is_active: row.is_active}
      });
      _units = _units.filter((row)=> parseFloat(row.number) > 0);

      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        taxes: this.data_taxes,
        group_items: this.group_items,
        statistics_item: this.statistics_item,
        laundry: this.services,
        tab_name: this.tab_name,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/collected-items');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.brand = response.data.data.brand;
        this.unit = response.data.data.unit;
        this.image_url = response.data.data.image_url;
        this.data_taxes = response.data.data.taxes;

        this.services = response.data.data.laundry;

        if (response.data.data.taxes.length <= 0) {
          this.addToRepeater();
        }

        this.group_items = response.data.data.group_items;
        if (response.data.data.group_items && response.data.data.group_items.length <= 0) {
          this.addItemRowToList();
        }

        this.reloadUploadAttachment = true;
      });
    },
    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },

    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getBrands() {
      ApiService.get(this.mainRouteDependency + "/brands").then((response) => {
        this.brands = response.data.data;
      });
    },
    getTaxes() {
      ApiService.get(this.mainRouteDependency + "/taxes").then((response) => {
        this.taxes = response.data.data;
      });
    },
    updateValue(value) {
      this.data.category_id = value.id;
      this.data.allowable_return_days = value.allowable_return_days;
      this.getAspects();
    },
    loadOptions() {
    },
    listenerAttachment(event) {
      if (event) {
        this.image_url = event.pathDB;
        this.data.image = event.name;
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        id: Math.floor((Math.random() * 10000000))
      }
      if (!this.tags_list) {
        this.tags_list = [];
      }
      this.tags_list.push(tag);

      if (!this.data.tags) {
        this.data.tags = [];
      }
      this.data.tags.push(tag)
    },
    addToRepeater() {
      this.repeater_taxes.priority = this.data_taxes.length + 1;
      this.data_taxes.unshift(this.repeater_taxes);
      this.repeater_taxes = {id: null, tax: this.user_personal_info.tax, percentage: null, priority: null, minimum: 0};
    },
    deleteFromRepeater(index) {
      this.data_taxes.splice(index, 1);
      this.data_taxes = this.data_taxes.map((row, index) =>{
        row.priority = this.data_taxes.length - index;
        return row;
      });
      this.calcPercentageForTotal();
    },

    addUnitToRepeater() {
      this.data_units.unshift(this.repeater_unit);
      this.repeater_unit = {unit: null, unit_id: null, number: null, is_active: true};
    },
    deleteUnitFromRepeater(index) {
      this.data_units.splice(index, 1);
    },
    onChangeUnitsRepeater: _.debounce(function (index){
      if (this.data_units[index].unit){
        this.data_units[index].unit_id = this.data_units[index].unit.id;
        if (this.data_units[index].unit_id == this.data.unit_id){
          this.data_units[index].unit = null;
          this.data_units[index].unit_id = null;
        }
      }else{
        this.data_units[index].unit_id = null;
      }

      let _attr = String(this.data_units[index].number).split('.');
      if (_attr[1] && _attr[1].length > 3){
        this.data_units[index].number = parseFloat(this.data_units[index].number).toFixed(3);
      }

      if (isNaN(this.data_units[index].number)) {
        this.data_units[index].number = 0;
      }
      if (parseFloat(this.data_units[index].number) > 999999.999){
        this.data_units[index].number = 999999.999;
      }
      if (parseFloat(this.data_units[index].number) < 0){
        this.data_units[index].number = 0;
      }
    }, 300),

    // onChangeIsMin: _.debounce(function () {
    //   if(this.data.is_min){
    //     this.data_units = this.data_units.map((row)=>{
    //       if (!row.number || (row.number && parseFloat(row.number) < 1)) {
    //         row.number = 1;
    //       }
    //
    //       return row;
    //     })
    //   }
    // }, 100),

    onPriorityTaxChanged: _.debounce(function (index){
      if ((this.data_taxes.length - 1) == index){
        this.data_taxes[index].priority = 1;
      }else {
        this.data_taxes[index].priority = this.data_taxes[index + 1].priority + 1
      }

    }, 100),

    handlingPercentageValue(index) {
      let _value_rate = this.data_taxes[index].tax.value_rate;
      this.data_taxes[index].percentage = _value_rate;
    },
    calcPercentageForTotal() {
      // if (this.data_taxes.length > 0) {
      //   this.data.total_taxes = this.$_.sumBy(this.data_taxes, (row) => {
      //     return row.percentage ? parseFloat(row.percentage) : 0;
      //   });
      // } else {
      //   this.data.total_taxes = 0;
      // }
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/9").then((response) => {
        this.data.sku_code = response.data.data.code;
      });
    },

    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/items`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    addItemRowToList(_item = null) {
      this.group_items.unshift(_item ? _item : this.group_items_repeater);
      this.group_items_repeater = {
        id: null,
        name: null,
        sku_code: null,
        sale_price: null,
        purchase_price: null,
        open_stock_value: null,
        open_stock_quantity: null
      };
    },
    removeItemRowFromList(index = 0) {
      if (this.group_items.length > 1) {
        this.group_items.splice(index, 1);
      }
    },
    getAspects() {
      ApiService.get(this.mainRouteDependency + '/aspects_for_category/' + this.data.category_id).then((response) => {
        this.aspects_list = response.data.data.map((row) => {
          return {
            id: row.id,
            name: row.name,
            attribute_style: row.attribute_style,
            value_type: row.value_type,
            aspect_values: row.aspect_values.map((val) => {
              return {
                id: val.id,
                name: val.name,
              }
            }),
            values: row.attribute_style == 2 ? [] : null,
          };
        });
      });

    },
    repeatingData() {
      if (this.isEditing){
        return;
      }
      this.group_items = [];
      // this.inner_name = this.data.name ? this.data.name : '';
      let _name_list = [];
      this.aspects_list.forEach((row) => {
        if (row.values) {
          if (typeof row.values == "object") {
            let _inner_name_arr = [];
            row.values.forEach((row) => {
              _inner_name_arr.push(row.name);
            });
            _name_list.push(_inner_name_arr);
          } else {
            _name_list.push([row.values]);
          }
        }


      });
      if (_name_list.length > 0) {
        let _names = this.cartesian(..._name_list);
        if (_names.length > 0) {
          _names.forEach((name) => {
            let _name = (this.data.name ? this.data.name : '') + '-' + name.join('-');
            let item = {
              id: null,
              name: _name,
              sku_code: null,
              sale_price: null,
              purchase_price: null,
              open_stock_value: null,
              open_stock_quantity: null
            }
            this.addItemRowToList(item);
          });

        }
      }


    },
    cartesian(...args) {
      let r = [], max = args.length - 1;

      function helper(arr, i) {
        for (let j = 0, l = args[i].length; j < l; j++) {
          let a = arr.slice(0); // clone arr
          a.push(args[i][j]);
          if (i == max)
            r.push(a);
          else
            helper(a, i + 1);
        }
      }

      helper([], 0);
      return r;
    },

    getUnits() {
        ApiService.get(`${this.mainRouteDependency}/units`).then((response) => {
          this.units = response.data.data;
        });

    },

    getStatisticsItem() {
      if (this.idEdit) {
        ApiService.get(`settings/inventory_statistics`, {params: {is_paginate: 0, product_id: this.idEdit}}).then((response) => {
          this.statistics_item = response.data.data;
        });
      }
    },


    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.repeater_taxes.tax = response.data.data.tax;
        if (this.data_taxes[0]) {
          this.data_taxes[0].tax = response.data.data.tax;
          this.data_taxes[0].percentage = response.data.data.tax.value_rate ? response.data.data.tax.value_rate : 0;
          this.data_taxes[0].priority = 1;
        }
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.collected_items"),
      route: '/items/collected-items'
    }, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);


    this.getCategories();
    this.getBrands();
    this.getTaxes();
    this.getUnits();

    if (this.idEdit) {
      this.getData();
      this.getStatisticsItem();
    } else {
      if (this.data_taxes.length <= 0) {
        this.addToRepeater();
      }
      this.defaultDataForUser();
      this.getPlanMessage();

      if (this.group_items.length <= 0) {
        this.addItemRowToList();
      }
      this.getCode();
    }

  },
};
</script>


